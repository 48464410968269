import React, { useState } from "react"
import { Global } from "@emotion/core"
import { graphql, useStaticQuery } from "gatsby"
import { globalStyles } from "../styles"
import Layout from "../components/layout"
import PageHero from "../components/pageHero"
import Register from "../components/register"
import Footer from "../components/footer"
import Step from "../components/step"
import MDX from "../components/mdx"
import styled from "@emotion/styled"
import SEO from "../components/seo"

import MobileNavigation from "../components/navigationMobile"
import mediaqueries from "../styles/media"

interface Props {
  location: any
}

const siteQuery = graphql`
  query demoQuery {
    demo: mdx(frontmatter: { gatsbyTitle: { eq: "demo" } }) {
      id
      frontmatter {
        title
      }
      body
    }
    firstStep: mdx(frontmatter: { gatsbyTitle: { eq: "demo-first-step" } }) {
      id
      frontmatter {
        title
        link
        login
        pass
      }
      body
    }
    secondStep: mdx(frontmatter: { gatsbyTitle: { eq: "demo-second-step" } }) {
      id
      frontmatter {
        link
        title
      }
      body
    }
  }
`

const Demo: React.FC<Props> = ({ location }) => {
  const data = useStaticQuery(siteQuery)
  const [isOpen, setOpen] = useState(false)
  const { demo, firstStep, secondStep } = data
  return (
    <>
      <Global styles={globalStyles} />
      <SEO title={"Demo"} />
      <MobileNavigation isOpen={isOpen} setOpen={setOpen} location={location} />
      <PageHero title={demo.frontmatter.title} location={location} />
      <Main>
        <Layout>
          <DemoBodyHolder>
            <MDX content={demo.body} />
          </DemoBodyHolder>
          <StepsHolder>
            <Step
              number={1}
              title={firstStep.frontmatter.title}
              body={firstStep.body}
              link={firstStep.frontmatter.link}
              login={firstStep.frontmatter.login}
              pass={firstStep.frontmatter.pass}
            />
            <Step
              number={2}
              title={secondStep.frontmatter.title}
              body={secondStep.body}
              link={secondStep.frontmatter.link}
            />
          </StepsHolder>
        </Layout>
        <Register />
        <Footer />
      </Main>
    </>
  )
}

export default Demo

const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const DemoBodyHolder = styled.div`
  max-width: 600px;
  margin-left: 10px;
  line-height: 25px;
  margin-top: -200px;
  margin-bottom: 100px;

  & strong {
    color: ${(p: any) => p.theme.colors.secondary};
  }

  & a {
    color: ${(p: any) => p.theme.colors.secondary};
  }

  & p {
    margin-top: 10px;
    font-size: 16px;
  }

  ${mediaqueries.desktop`
    margin-top: 0px;
`};
`

const StepsHolder = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 200px;

  ${mediaqueries.desktop`
  grid-template-columns: 1fr;
  margin-bottom: 100px;
`};
`
