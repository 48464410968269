export function getFirstWord(str: string) {
  let spaceIndex = str.indexOf(" ")
  return spaceIndex === -1 ? str : str.substr(0, spaceIndex)
}

export function removeTheFirstWord(str: string) {
  const parts = str.split(" ")
  parts.shift()
  return parts.join(" ")
}

export function getTheLastWord(str: string) {
  const n = str.split(" ")
  return n[n.length - 1]
}

export function removeTheLastWord(str: string) {
  const lastIndex = str.lastIndexOf(" ")
  return str.substring(0, lastIndex)
}
