import React from "react"
import styled from "@emotion/styled"
import MDX from "../mdx"
import { getTheLastWord, removeTheLastWord } from "../../utils/utils"

interface Props {
  number: number
  title: string
  body: any
  link?: string
  login?: string
  pass?: string
}

const Step: React.FC<Props> = ({ number, title, body, link, login, pass }) => {
  const end = getTheLastWord(title)
  const firstWords = removeTheLastWord(title)

  return (
    <StepHolder>
      <TitleHolder>
        <Number>{number}</Number>
        <Title>
          {firstWords}
          <span>{` ${end}`}</span>
        </Title>
      </TitleHolder>
      {link && (
        <Links>
          <span>link: </span>
          <a href={link} target="_blank" rel="noopener noreferrer">
            {link}
          </a>
        </Links>
      )}
      {login && (
        <Links>
          <span>login: </span>
          {login}
        </Links>
      )}
      {pass && (
        <Links>
          <span>hasło: </span>
          {pass}
        </Links>
      )}
      <Body>
        <MDX content={body} />
      </Body>
    </StepHolder>
  )
}

export default Step

const StepHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin: 0 auto;
`

const TitleHolder = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 50px auto;
  min-height: 130px;
`

const Number = styled.p`
  font-family: ${(p: any) => p.theme.fonts.numberFont};
  font-style: normal;
  font-weight: normal;
  font-size: 72px;
  margin-right: 25px;
  letter-spacing: 0.015em;
  color: ${(p: any) => p.theme.colors.secondary};
`

const Title = styled.h2`
  font-family: ${(p: any) => p.theme.fonts.exo};
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  margin-top: 30px;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  color: ${(p: any) => p.theme.colors.primary};

  & span {
    color: ${(p: any) => p.theme.colors.secondary};
  }
`

const Links = styled.p`
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: ${(p: any) => p.theme.colors.secondary};

  & span {
    color: ${(p: any) => p.theme.colors.gray};
  }

  & a {
    color: ${(p: any) => p.theme.colors.secondary};
  }
`

const Body = styled.div`
  margin-top: 50px;

  & p {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    margin-top: 30px;
    color: ${(p: any) => p.theme.colors.primary};

    & span {
      color: ${(p: any) => p.theme.colors.gray};
    }

    & a {
      color: ${(p: any) => p.theme.colors.secondary};
    }

    & strong {
      color: ${(p: any) => p.theme.colors.secondary};
    }
  }
`
